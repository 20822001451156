import React from 'react';
import './Project.css';

const Project = function(props) {
  const {
    alt_text,
    host,
    title,
    client_repo,
    server_repo,
    project_desc,
    stack,
    pathname,
    image
    } = props;
  return (
    <div id='project-slider' className="detail-container project-container">
      <h3>Web Based</h3>
      <img src={image} alt={alt_text} className="projectImg" />
      {(pathname === '/projects')
        ? <h3><a className='heading' rel='noreferrer' target='_blank' href={host}>{title}</a></h3>
        : <h4><a className='heading' rel='noreferrer' target='_blank' href={host}>{title}</a></h4>
      }
      <div className='repo-container'>
        <a rel='noreferrer' target='_blank' href={client_repo}>Client Repo</a>
        {(server_repo)
          ? <a rel='noreferrer' target='_blank' href={server_repo}>Server Repo</a>
          : null }
      </div>
      <p>{project_desc} </p>
      {stack == null ? <></> : <p><strong>Stack: </strong>{stack}</p>}
    </div>
  )
}

export default Project;